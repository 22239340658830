import { ifAuthenticated } from "@/router/auth";

const zdsGenerallyBlockingPeriods = [
  {
    path: "/zds/generally/blockingperiods",
    name: "ZDSGenerallyBlockingPeriods",
    component: () =>
      import("@/modules/zds/generally/blockingperiods/views/BlockingPeriods.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_generally_blockingperiods" },
  },
  {
    path: "/zds/generally/blockingperiods/create",
    name: "ZDSGenerallyBlockingPeriodsCreate",
    component: () =>
      import("@/modules/zds/generally/blockingperiods/views/BlockingPeriodsCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_generally_blockingperiods" },
  },
  {
    path: "/zds/generally/blockingperiods/:id/edit",
    name: "ZDSGenerallyBlockingPeriodsEdit",
    component: () =>
      import("@/modules/zds/generally/blockingperiods/views/BlockingPeriodsEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_generally_blockingperiods" },
  },
];

export default zdsGenerallyBlockingPeriods;
