import { ifAuthenticated } from "@/router/auth";

const zdsAdministrationLocations = [
  {
    path: "/zds/administration/locations",
    name: "ZDSAdministrationLocations",
    component: () =>
      import("@/modules/zds/administration/locations/views/Locations.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_administration_locations" },
  },
  {
    path: "/zds/administration/locations/create",
    name: "ZDSAdministrationLocationsCreate",
    component: () =>
      import("@/modules/zds/administration/locations/views/LocationsCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_administration_locations" },
  },
  {
    path: "/zds/administration/locations/:id/edit",
    name: "ZDSAdministrationLocationsEdit",
    component: () =>
      import("@/modules/zds/administration/locations/views/LocationsEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_administration_locations" },
  },
];

export default zdsAdministrationLocations;
