const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setData(state, payload) {
    state.data = payload.data;
    state.total = payload.total;
  },
  setErrors(state, errors) {
    state.errors = errors;
  },
  setInProcess(state, inProcess) {
    state.inProcess = inProcess;
  },
  setSearch(state, value) {
    state.search = value;
  },
  setType(state, value) {
    state.type = value;
  },
  setYear(state, value) {
    state.year = value;
  },
  setLocation(state, value) {
    state.location = value;
  },
  setStatus(state, value) {
    state.status = value;
  },
  setArchived(state, value) {
    state.archived = value;
  },
  setSortBy(state, value) {
    state.sortBy = value;
  },
  setPage(state, value) {
    state.page = value;
  },
  setPerPage(state, value) {
    state.perPage = value;
  },
  setDefaults(state) {
    state.page = 1;
    state.perPage = "10";
    state.state = "";
    state.sortBy = "date_ASC";
    state.year = "";
    state.type = "";
    state.location = "";
    state.status = "";
    state.archived = "";
    state.search = "";
  },
  setCreateInProcess(state, inProcess) {
    state.create.inProcess = inProcess;
  },
  setCreateErrors(state, payload) {
    state.create.errors = payload.errors;
  },
  resetCreateErrors(state) {
    state.create.errors = [];
  },
  setEditLoading(state, loading) {
    state.edit.loading = loading;
  },
  setEditInProcess(state, inProcess) {
    state.edit.inProcess = inProcess;
  },
  setEditErrors(state, errors) {
    state.edit.errors = errors;
  },
  setFilterLoading(state, loading) {
    state.filter.loading = loading;
  },
  setFilterData(state, data) {
    state.filter.dynamic.year = data.year;
    state.filter.dynamic.location = data.location;
  },
  setLoadingTags(state, loading) {
    state.loadingTags = loading;
  },
  setLoadingLocations(state, loading) {
    state.loadingLocations = loading;
  },
};

export default mutations;
