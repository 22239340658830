import { ifAuthenticated } from "@/router/auth";

const zdsGenerallyTags = [
  {
    path: "/zds/generally/tags",
    name: "ZDSGenerallyTags",
    component: () =>
      import("@/modules/zds/generally/tags/views/Tags.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_generally_tags" },
  },
  {
    path: "/zds/generally/tags/create",
    name: "ZDSGenerallyTagsCreate",
    component: () =>
      import("@/modules/zds/generally/tags/views/TagsCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_generally_tags" },
  },
  {
    path: "/zds/generally/tags/:id/edit",
    name: "ZDSGenerallyTagsEdit",
    component: () =>
      import("@/modules/zds/generally/tags/views/TagsEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_generally_tags" },
  },
];

export default zdsGenerallyTags;
