import { ifAuthenticated } from "@/router/auth";

const sfvAdministrationTenants = [
  {
    path: "/sfv/administration/tenants",
    name: "SFVAdministrationTenants",
    component: () =>
      import("@/modules/sfv/administration/tenants/views/Tenants.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "sfv_administration_tenants" },
  },
  {
    path: "/sfv/administration/tenants/create",
    name: "SFVAdministrationTenantsCreate",
    component: () =>
      import("@/modules/sfv/administration/tenants/views/TenantsCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "sfv_administration_tenants" },
  },
  {
    path: "/sfv/administration/tenants/:id/edit",
    name: "SFVAdministrationTenantsEdit",
    component: () =>
      import("@/modules/sfv/administration/tenants/views/TenantsEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "sfv_administration_tenants" },
  },
];

export default sfvAdministrationTenants;
