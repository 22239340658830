import searchResources from "@/modules/sfv/administration/happenings/store/modal/searchResources/";
import listHolidays from "@/modules/sfv/administration/happenings/store/modal/listHolidays/";
import listBlockingPeriods from "@/modules/sfv/administration/happenings/store/modal/listBlockingPeriods/";
import addTodosEntry from "@/modules/sfv/administration/happenings/store/modal/addTodosEntry/";
import editTodosEntry from "@/modules/sfv/administration/happenings/store/modal/editTodosEntry/";
import listDetailTodos from "@/modules/sfv/administration/happenings/store/modal/listDetailTodos/";
import simpleCalendar from "@/modules/sfv/administration/happenings/store/modal/simpleCalendar/";

const state = () => ({});

const getters = {};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    searchResources,
    listHolidays,
    listBlockingPeriods,
    addTodosEntry,
    editTodosEntry,
    listDetailTodos,
    simpleCalendar,
  },
};
