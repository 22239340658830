const state = () => ({
  errors: [],
  loading: true,
  data: [],
  total: 0,
  search: "",
  section: "",
  category: "",
  status: "",
  archived: "",
  sortBy: "updatedAt_DESC",
  page: 1,
  perPage: "10",
  inProcess: false,
  create: {
    inProcess: false,
    errors: [],
  },
  edit: {
    loading: false,
    inProcess: false,
    errors: [],
  },
  hints: {
    section: "",
    label: "",
    category: "",
    locked: ""
  },
  options: {
    section: [
      { name: "HWS", value: "HWS" },
      { name: "SFV", value: "SFV" },
      { name: "ZDS", value: "ZDS" },
    ],
    category: [
      { name: "Ereignisse", value: "HAPPENINGS" },
      { name: "Resourcen", value: "RESOURCES" },
      { name: "Sperrzeiten", value: "BLOCKINGPERIODS" },
      { name: "Standorte", value: "LOCATIONS" },
      { name: "Mieter", value: "TENANT" },
    ],
    status: [
      { name: "Aktiviert", value: "ENABLED" },
      { name: "Deaktiviert", value: "DISABLED" },
    ],
    sortBy: [
      { name: "Sortierung: Geändert (absteigend)", value: "updatedAt_DESC" },
      { name: "Sortierung: Geändert (aufsteigend)", value: "updatedAt_ASC" },
      { name: "Sortierung: Angelegt (absteigend)", value: "createdAt_DESC" },
      { name: "Sortierung: Angelegt (aufsteigend)", value: "createdAt_ASC" },
    ],
    perPage: ["10", "20", "30", "40", "50", "100"],
  },
});

export default state;
