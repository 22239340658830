import api from "@/api";
import _ from "lodash";

const actions = {
  list({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit("setLoading", true);
      commit("setErrors", []);
      api
        .get("/v1/hws/generally/holidays", data)
        .then((response) => {
          if (response.data.success) {
            commit("setData", response.data.payload);
            commit("setLoading", false);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((err) => {
          dispatch("gobalError", err, { root: true });
        });
    });
  },

  create({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit("setCreateInProcess", true);
      commit("resetCreateErrors");
      api
        .post("/v1/hws/generally/holidays", data)
        .then((response) => {
          if (response.data.success) {
            commit("setCreateInProcess", false);
            resolve(response);
          } else {
            const { errors } = response.data.payload;

            commit("setCreateErrors", {
              errors,
            });
            commit("setCreateInProcess", false);
            reject(response);
          }
        })
        .catch((err) => {
          commit("setCreateInProcess", false);
          dispatch("gobalError", err, { root: true });
        });
    });
  },

  show({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      commit("setEditInProcess", false);
      commit("setEditLoading", true);
      commit("setEditErrors", []);
      api
        .get(_.join(["/v1/hws/generally/holidays", encodeURI(id)], "/"))
        .then((response) => {
          if (response.data.success) {
            commit("setEditLoading", false);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((err) => {
          dispatch("gobalError", err, { root: true });
        });
    });
  },

  update({ commit, dispatch }, payload) {
    const { id, data } = payload;

    return new Promise((resolve, reject) => {
      commit("setEditInProcess", true);
      commit("setEditErrors", []);
      api
        .patch(
          _.join(["/v1/hws/generally/holidays", encodeURI(id)], "/"),
          data
        )
        .then((response) => {
          if (response.data.success) {
            commit("setEditInProcess", false);
            resolve(response);
          } else {
            commit("setEditErrors", response.data.payload.errors);
            commit("setEditInProcess", false);
            reject(response);
          }
        })
        .catch((err) => {
          commit("setEditInProcess", false);
          dispatch("gobalError", err, { root: true });
        });
    });
  },

  delete({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit("setInProcess", true);
      commit("setErrors", []);
      api
        .post("/v1/hws/generally/holidays/delete", data)
        .then((response) => {
          if (response.data.success) {
            commit("setInProcess", false);
            resolve(response);
          } else {
            commit("setErrors", response.data.payload.errors);
            commit("setInProcess", false);
            reject(response);
          }
        })
        .catch((err) => {
          commit("setInProcess", false);
          dispatch("gobalError", err, { root: true });
        });
    });
  },
  enable({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit("setInProcess", true);
      commit("setErrors", []);
      api
        .post("/v1/hws/generally/holidays/enable", data)
        .then((response) => {
          if (response.data.success) {
            commit("setInProcess", false);
            resolve(response);
          } else {
            commit("setErrors", response.data.payload.errors);
            commit("setInProcess", false);
            reject(response);
          }
        })
        .catch((err) => {
          commit("setInProcess", false);
          dispatch("gobalError", err, { root: true });
        });
    });
  },
  disable({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit("setInProcess", true);
      commit("setErrors", []);
      api
        .post("/v1/hws/generally/holidays/disable", data)
        .then((response) => {
          if (response.data.success) {
            commit("setInProcess", false);
            resolve(response);
          } else {
            commit("setErrors", response.data.payload.errors);
            commit("setInProcess", false);
            reject(response);
          }
        })
        .catch((err) => {
          commit("setInProcess", false);
          dispatch("gobalError", err, { root: true });
        });
    });
  },
  archive({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit("setInProcess", true);
      commit("setErrors", []);
      api
        .post("/v1/hws/generally/holidays/archive", data)
        .then((response) => {
          if (response.data.success) {
            commit("setInProcess", false);
            resolve(response);
          } else {
            commit("setErrors", response.data.payload.errors);
            commit("setInProcess", false);
            reject(response);
          }
        })
        .catch((err) => {
          dispatch("gobalError", err, { root: true });
        });
    });
  },

  listFilter({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit("setFilterLoading", true);
      api
        .get("/v1/hws/generally/holidays/filter")
        .then((response) => {
          if (response.data.success) {
            commit("setFilterData", response.data.payload.data);
            commit("setFilterLoading", false);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((err) => {
          dispatch("gobalError", err, { root: true });
        });
    });
  },

  locations({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit("setLoadingLocations", true);
      api
        .get("/v1/hws/generally/holidays/locations")
        .then((response) => {
          if (response.data.success) {
            commit("setLoadingLocations", false);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((err) => {
          dispatch("gobalError", err, { root: true });
        });
    });
  },
  locationsEnabled({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit("setLoadingLocations", true);
      api
        .get("/v1/hws/generally/holidays/locations/enabled")
        .then((response) => {
          if (response.data.success) {
            commit("setLoadingLocations", false);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((err) => {
          dispatch("gobalError", err, { root: true });
        });
    });
  },
};

export default actions;
