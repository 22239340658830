import { ifAuthenticated } from "@/router/auth";

const zdsAdministrationResources = [
  {
    path: "/zds/administration/resources",
    name: "ZDSAdministrationResources",
    component: () =>
      import("@/modules/zds/administration/resources/views/Resources.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_administration_resources" },
  },
  {
    path: "/zds/administration/resources/create",
    name: "ZDSAdministrationResourcesCreate",
    component: () =>
      import("@/modules/zds/administration/resources/views/ResourcesCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_administration_resources" },
  },
  {
    path: "/zds/administration/resources/:id/edit",
    name: "ZDSAdministrationResourcesEdit",
    component: () =>
      import("@/modules/zds/administration/resources/views/ResourcesEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_administration_resources" },
  },
];

export default zdsAdministrationResources;
