import { ifAuthenticated } from "@/router/auth";

const sfvGenerallyBlockingPeriods = [
  {
    path: "/sfv/generally/blockingperiods",
    name: "SFVGenerallyBlockingPeriods",
    component: () =>
      import("@/modules/sfv/generally/blockingperiods/views/BlockingPeriods.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "sfv_generally_blockingperiods" },
  },
  {
    path: "/sfv/generally/blockingperiods/create",
    name: "SFVGenerallyBlockingPeriodsCreate",
    component: () =>
      import("@/modules/sfv/generally/blockingperiods/views/BlockingPeriodsCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "sfv_generally_blockingperiods" },
  },
  {
    path: "/sfv/generally/blockingperiods/:id/edit",
    name: "SFVGenerallyBlockingPeriodsEdit",
    component: () =>
      import("@/modules/sfv/generally/blockingperiods/views/BlockingPeriodsEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "sfv_generally_blockingperiods" },
  },
];

export default sfvGenerallyBlockingPeriods;
