const state = () => ({
  errors: [],
  loading: true,
  data: [],
  inProcess: false,
  total: 0,
  writeDenied: "",
});

export default state;
