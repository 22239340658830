import { ifAuthenticated } from "@/router/auth";

const sfvAdministrationLocations = [
  {
    path: "/sfv/administration/locations",
    name: "SFVAdministrationLocations",
    component: () =>
      import("@/modules/sfv/administration/locations/views/Locations.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "sfv_administration_locations" },
  },
  {
    path: "/sfv/administration/locations/create",
    name: "SFVAdministrationLocationsCreate",
    component: () =>
      import("@/modules/sfv/administration/locations/views/LocationsCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "sfv_administration_locations" },
  },
  {
    path: "/sfv/administration/locations/:id/edit",
    name: "SFVAdministrationLocationsEdit",
    component: () =>
      import("@/modules/sfv/administration/locations/views/LocationsEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "sfv_administration_locations" },
  },
];

export default sfvAdministrationLocations;
