import administrationMembers from "@/modules/zds/administration/members/store";
import administrationContacts from "@/modules/zds/administration/contacts/store";
import generallySweepDistricts from "@/modules/zds/generally/sweepdistricts/store";
import generallyKlartext from "@/modules/zds/generally/klartext/store";
import generallyNewsletter from "@/modules/zds/generally/newsletter/store";
import websiteDocuments from "@/modules/zds/website/documents/store";
import websiteMagazines from "@/modules/zds/website/magazines/store";
import websiteTutorials from "@/modules/zds/website/tutorials/store";
import appWorkingAids from "@/modules/zds/app/workingAids/store";
import appSessions from "@/modules/zds/app/sessions/store";
import mediadataImages from "@/modules/zds/mediadata/images/store";
import administrationLocalGroups from "@/modules/zds/administration/localGroups/store";
import websiteBlogs from "@/modules/zds/website/blogs/store";
import administrationLists from "@/modules/zds/administration/lists/store";
import appConferences from "@/modules/zds/app/conferences/store";
import appMessages from "@/modules/zds/app/messages/store";
import generallyTags from "@/modules/zds/generally/tags/store";
import administrationLocations from "@/modules/zds/administration/locations/store";
import administrationResources from "@/modules/zds/administration/resources/store";
import generallyHolidays from "@/modules/zds/generally/holidays/store";
import generallyBlockingPeriods from "@/modules/zds/generally/blockingperiods/store";

const state = () => ({
  memberStatusList: [
    { id: "EMPLOYER", name: "Arbeitgeber" },
    { id: "EMPLOYEE", name: "Arbeitnehmer" },
    { id: "COMPANY_OR_INSTITUTE", name: "Firma/Institut" },
    { id: "TRAINEE", name: "Lehrgangsteilnehmer" },
    { id: "OTHER", name: "Sonstiges" },
  ],
  statusCodeList: [
    { id: "BZ", name: "Beitragszahler" },
    { id: "AL1", name: "Arbeitslosengeld 1" },
    { id: "AL2", name: "Arbeitslosengeld 2" },
    { id: "AZM", name: "Azubi-Mitglied" },
    { id: "EJ", name: "Elternzeit" },
    { id: "K", name: "Krank" },
    { id: "MJ", name: "Minijob" },
    { id: "MP", name: "Meisterkurs" },
    { id: "MPH", name: "Meisterkurs HWS" },
    { id: "R", name: "Rentner" },
    { id: "ST", name: "Student" },
    { id: "TZ", name: "Teilzeit" },
    { id: "GMA", name: "Gastmitgliedschaft mit Abo" },
    { id: "GM", name: "Gastmitglied ohne Abo" },
    { id: "NAZ", name: "Azubi Nichtmitglied" },
  ],
  promoCodesList: [
    { id: "BS-Lehrer_AH", name: "BS-Lehrer_AH" },
    { id: "externe FöM", name: "externe FöM" },
    { id: "FöM mit Kehrbezirk", name: "FöM mit Kehrbezirk" },
    { id: "FöM ohne Kehrbezirk", name: "FöM ohne Kehrbezirk" },
    { id: "Hochwasserhilfe", name: "Hochwasserhilfe" },
    { id: "keine Werbung HWS", name: "keine Werbung HWS" },
    { id: "keine Werbung SFV", name: "keine Werbung SFV" },
    { id: "keine Werbung SIB", name: "keine Werbung SIB" },
    { id: "keine Werbung ZDS", name: "keine Werbung ZDS" },
    { id: "Klartext", name: "Klartext" },
    { id: "kein Klartext", name: "kein Klartext" },
    { id: "Mahnwache 2015", name: "Mahnwache 2015" },
    { id: "SV_Mahnung", name: "SV_Mahnung" },
    { id: "Veranstaltung BG Baden", name: "Veranstaltung BG Baden" },
    {
      id: "Weihnachtskarten 1.Vorsitzender",
      name: "Weihnachtskarten 1.Vorsitzender",
    },
    { id: "Weihnachtskarten F/V", name: "Weihnachtskarten F/V" },
    { id: "Weihnachtskarten HWS", name: "Weihnachtskarten HWS" },
    { id: "Weihnachtskarten T/B", name: "Weihnachtskarten T/B" },
    { id: "Weihnachtskarten SIB", name: "Weihnachtskarten SIB" },
    { id: "Weihnachtskarten Verlag", name: "Weihnachtskarten Verlag" },
    { id: "WerbegeschenkeZVTag", name: "WerbegeschenkeZVTag" },
  ],
  countryISOCodeList: [
    { id: "DE", name: "Deutschland" },
    { id: "FR", name: "Frankreich" },
    { id: "AT", name: "Österreich" },
  ],
  germanStatesList: [
    { id: "59af8c9cb85382b0208b4569", name: "Baden-Württemberg" },
    { id: "59af8ca3b85382b0208b456a", name: "Bayern" },
    { id: "59af8ca9b85382b0208b456b", name: "Berlin" },
    { id: "59af8cb0b85382b0208b456c", name: "Brandenburg" },
    { id: "59af8cb7b85382b0208b456d", name: "Bremen" },
    { id: "59af8cbeb85382b0208b456e", name: "Hamburg" },
    { id: "59af8cc9b85382b0208b456f", name: "Hessen" },
    { id: "59af8cd3b85382b0208b4570", name: "Mecklenburg-Vorpommern" },
    { id: "59af8cddb85382b0208b4571", name: "Niedersachsen" },
    { id: "59af8ce4b85382b0208b4572", name: "Nordrhein-Westfalen" },
    { id: "59af8cecb85382b0208b4573", name: "Rheinland-Pfalz" },
    { id: "59af8cf6b85382e4208b4567", name: "Saarland" },
    { id: "59af8cfcb85382e4208b4568", name: "Sachsen" },
    { id: "59af8d04b85382e4208b4569", name: "Sachsen-Anhalt" },
    { id: "59af8d13b85382e4208b456a", name: "Schleswig-Holstein" },
    { id: "59af8d1bb85382e4208b456b", name: "Thüringen" },
  ],
  websiteCategoryList: [
    { id: "5c5350183baafc73dcffda3d", name: "Arbeitsschutz" },
    { id: "5f86d5b75f9dc440194fd3b3", name: "Berufspolitik" },
    { id: "5f4fcc0e19beae6fe7b259ba", name: "Brandschutz" },
    { id: "5c5350343baafc73dcffda40", name: "Bundesverband" },
    { id: "5f4fcbff19beae6fe7b259b9", name: "Energie" },
    { id: "5f4fcbf219beae6fe7b259b8", name: "Gesetze" },
    { id: "5c5350213baafc73dcffda3e", name: "Gesetze und Energie" },
    { id: "5f4fcc1a19beae6fe7b259bb", name: "Politik" },
    { id: "5c53502a3baafc73dcffda3f", name: "Regionales" },
    { id: "5c53503b3baafc73dcffda41", name: "Technik und Bildung" },
  ],
  functionsList: [
    { id: "Altgeselle", name: "Altgeselle" },
    { id: "Ansprechpartner Landkreise", name: "Ansprechpartner Landkreise" },
    { id: "Behörde", name: "Behörde" },
    {
      id: "Berufsbildungsausschuss AG HWK Innung",
      name: "Berufsbildungsausschuss AG HWK Innung",
    },
    {
      id: "Berufsbildungsausschuss AN HWK Innung",
      name: "Berufsbildungsausschuss AN HWK Innung",
    },
    { id: "BG Bau AG", name: "BG Bau AG" },
    { id: "BG Bau AN", name: "BG Bau AN" },
    { id: "BG-Vorsitzende", name: "BG-Vorsitzende" },
    { id: "Bildung", name: "Bildung" },
    { id: "Finanzen/Verwaltung BG", name: "Finanzen/Verwaltung BG" },
    { id: "Finanzen/Verwaltung LG", name: "Finanzen/Verwaltung LG" },
    { id: "Finanzen/Verwaltung RV", name: "Finanzen/Verwaltung RV" },
    { id: "Gesellenausschuss", name: "Gesellenausschuss" },
    {
      id: "Gesellenprüfungsausschuss AG",
      name: "Gesellenprüfungsausschuss AG",
    },
    {
      id: "Gesellenprüfungsausschuss AN",
      name: "Gesellenprüfungsausschuss AN",
    },
    { id: "HWK Vorstand", name: "HWK Vorstand" },
    { id: "HWK Vizepräsident", name: "HWK Vizepräsident" },
    { id: "Kreisobmann", name: "Kreisobmann" },
    { id: "Landesinnungsmeister", name: "Landesinnungsmeister" },
    { id: "Lehrervertreter", name: "Lehrervertreter" },
    {
      id: "LG-Vorsitzende/Landessprecher",
      name: "LG-Vorsitzende/Landessprecher",
    },
    { id: "Meisterprüfungsausschuss AG", name: "Meisterprüfungsausschuss AG" },
    { id: "Meisterprüfungsausschuss AN", name: "Meisterprüfungsausschuss AN" },
    {
      id: "Meisterprüfungsvorbereitungslehrgang",
      name: "Meisterprüfungsvorbereitungslehrgang",
    },
    {
      id: "Mitglied in der Vollversammkung",
      name: "Mitglied in der Vollversammkung",
    },
    { id: "Mitgliederbetreuer/in", name: "Mitgliederbetreuer/in" },
    { id: "Obermeister", name: "Obermeister" },
    { id: "Presse/Öffentlichkeit", name: "Presse/Öffentlichkeit" },
    {
      id: "Schlichtungsausschuss AZ AG HWK Innung",
      name: "Schlichtungsausschuss AZ AG HWK Innung",
    },
    {
      id: "Schlichtungsausschuss AZ AN HWK Innung",
      name: "Schlichtungsausschuss AZ AN HWK Innung",
    },
    { id: "Schornsteinfegermeister", name: "Schornsteinfegermeister" },
    { id: "Schriftführer/in", name: "Schriftführer/in" },
    { id: "TA-Mitglied", name: "TA-Mitglied" },
    { id: "TBA", name: "TBA" },
    { id: "Technik", name: "Technik" },
    { id: "Technik/Bildung BG", name: "Technik/Bildung BG" },
    { id: "Technik/Bildung LG", name: "Technik/Bildung LG" },
    { id: "Zusendung Monatsabschluss", name: "Zusendung Monatsabschluss" },
    { id: "Technik/ Bildung BG/LG", name: "Technik/ Bildung BG/LG" },
  ],
});

const getters = {};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    administrationMembers,
    administrationContacts,
    generallySweepDistricts,
    generallyKlartext,
    generallyNewsletter,
    websiteDocuments,
    websiteMagazines,
    websiteTutorials,
    appWorkingAids,
    appSessions,
    mediadataImages,
    administrationLocalGroups,
    websiteBlogs,
    administrationLists,
    appConferences,
    appMessages,
    generallyTags,
    administrationLocations,
    administrationResources,
    generallyHolidays,
    generallyBlockingPeriods,
  },
};
