import { ifAuthenticated } from "@/router/auth";

const sfvGenerallyTags = [
  {
    path: "/sfv/generally/tags",
    name: "SFVGenerallyTags",
    component: () =>
      import("@/modules/sfv/generally/tags/views/Tags.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "sfv_generally_tags" },
  },
  {
    path: "/sfv/generally/tags/create",
    name: "SFVGenerallyTagsCreate",
    component: () =>
      import("@/modules/sfv/generally/tags/views/TagsCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "sfv_generally_tags" },
  },
  {
    path: "/sfv/generally/tags/:id/edit",
    name: "SFVGenerallyTagsEdit",
    component: () =>
      import("@/modules/sfv/generally/tags/views/TagsEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "sfv_generally_tags" },
  },
];

export default sfvGenerallyTags;
