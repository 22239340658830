import { ifAuthenticated } from "@/router/auth";

const sfvGenerallyHolidays = [
  {
    path: "/sfv/generally/holidays",
    name: "SFVGenerallyHolidays",
    component: () =>
      import("@/modules/sfv/generally/holidays/views/Holidays.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "sfv_generally_holidays" },
  },
  {
    path: "/sfv/generally/holidays/create",
    name: "SFVGenerallyHolidaysCreate",
    component: () =>
      import("@/modules/sfv/generally/holidays/views/HolidaysCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "sfv_generally_holidays" },
  },
  {
    path: "/sfv/generally/holidays/:id/edit",
    name: "SFVGenerallyHolidaysEdit",
    component: () =>
      import("@/modules/sfv/generally/holidays/views/HolidaysEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "sfv_generally_holidays" },
  },
];

export default sfvGenerallyHolidays;
