const state = () => ({
  errors: [],
  loading: true,
  data: [],
  total: 0,
  search: "",
  tag: "",
  status: "",
  archived: "",
  sortBy: "updatedAt_DESC",
  page: 1,
  perPage: "10",
  inProcess: false,
  loadingTags: false,
  create: {
    inProcess: false,
    errors: [],
  },
  edit: {
    loading: false,
    inProcess: false,
    errors: [],
  },
  filter: {
    loading: false,
    dynamic: {
      tag: [],
    },
    static: {
      status: [
        { name: "Aktiviert", value: "ENABLED" },
        { name: "Deaktiviert", value: "DISABLED" },
      ],
    },
  },
  hints: {
    status: "",
    locked: "",
    label: "",
    abbreviation: "",
    street: "",
    streetAdditional: "",
    postcode: "",
    place: "",
    state: "",
    countryISOCode: "",
    telephone: "",
    mobile: "",
    email: "",
    anotherEmail: "",
    contact: "",
    tags: "",
  },
  options: {
    sortBy: [
      { name: "Sortierung: Geändert (absteigend)", value: "updatedAt_DESC" },
      { name: "Sortierung: Geändert (aufsteigend)", value: "updatedAt_ASC" },
      { name: "Sortierung: Angelegt (absteigend)", value: "createdAt_DESC" },
      { name: "Sortierung: Angelegt (aufsteigend)", value: "createdAt_ASC" },
    ],
    perPage: ["10", "20", "30", "40", "50", "100"],
  },
});

export default state;
