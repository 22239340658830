const state = () => ({
  errors: [],
  loading: true,
  data: [],
  total: 0,
  search: "",
  year: "",
  startAt: null,
  endAt: null,
  location: "",
  resource: "",
  status: "",
  type: "",
  archived: "",
  sortBy: "startAt_ASC",
  page: 1,
  perPage: "10",
  inProcess: false,
  loadingLocations: false,
  loadingResources: false,
  create: {
    inProcess: false,
    errors: [],
  },
  edit: {
    loading: false,
    inProcess: false,
    errors: [],
  },
  filter: {
    loading: false,
    dynamic: {
      year: [],
      location: [],
      resource: [],
    },
    static: {
      type: [
        { name: "Allgemein", value: "ALL" },
        { name: "Ressourcen", value: "RESOURCES" },
        { name: "Standorte", value: "LOCATIONS" },
      ],
      status: [
        { name: "Aktiviert", value: "ENABLED" },
        { name: "Deaktiviert", value: "DISABLED" },
      ],
    },
  },
  hints: {
    status: "",
    locked: "",
    label: "",
    description: "",
    type: "",
    startAt: "",
    endAt: "",
    allLocationIds: "",
    allResources: "",
    date: "",
  },
  options: {
    sortBy: [
      { name: "Sortierung: Geändert (absteigend)", value: "updatedAt_DESC" },
      { name: "Sortierung: Geändert (aufsteigend)", value: "updatedAt_ASC" },
      { name: "Sortierung: Angelegt (absteigend)", value: "createdAt_DESC" },
      { name: "Sortierung: Angelegt (aufsteigend)", value: "createdAt_ASC" },
      { name: "Sortierung: Beginn (absteigend)", value: "startAt_DESC" },
      { name: "Sortierung: Beginn (aufsteigend)", value: "startAt_ASC" },
    ],
    perPage: ["10", "20", "30", "40", "50", "100"],
  },
});

export default state;
