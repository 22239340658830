const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setData(state, payload) {
    state.data = payload.data;
    state.total = payload.total;
    state.writeDenied = payload.writeDenied;
  },
  setErrors(state, errors) {
    state.errors = errors;
  },
  setInProcess(state, inProcess) {
    state.inProcess = inProcess;
  },
};

export default mutations;
