import { ifAuthenticated } from "@/router/auth";

const zdsGenerallyHolidays = [
  {
    path: "/zds/generally/holidays",
    name: "ZDSGenerallyHolidays",
    component: () =>
      import("@/modules/zds/generally/holidays/views/Holidays.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_generally_holidays" },
  },
  {
    path: "/zds/generally/holidays/create",
    name: "ZDSGenerallyHolidaysCreate",
    component: () =>
      import("@/modules/zds/generally/holidays/views/HolidaysCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_generally_holidays" },
  },
  {
    path: "/zds/generally/holidays/:id/edit",
    name: "ZDSGenerallyHolidaysEdit",
    component: () =>
      import("@/modules/zds/generally/holidays/views/HolidaysEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_generally_holidays" },
  },
];

export default zdsGenerallyHolidays;
