const state = () => ({
  errors: [],
  loading: true,
  data: [],
  total: 0,
  totalHolidays: 0,
  totalBlockingPeriods: 0,
  search: "",
  section: "",
  year: "",
  startAt: null,
  endAt: null,
  location: "",
  resource: "",
  status: "",
  type: "",
  tag: "",
  archived: "",
  sortBy: "startAt_ASC",
  page: 1,
  perPage: "10",
  inProcess: false,
  loadingLocations: false,
  loadingResources: false,
  loadingTags: false,
  loadingResourcesTags: false,
  loadingTenants: false,
  create: {
    inProcess: false,
    errors: [],
  },
  edit: {
    loading: false,
    inProcess: false,
    errors: [],
  },
  filter: {
    loading: false,
    dynamic: {
      year: [],
      location: [],
      resource: [],
      tag: [],
    },
    static: {
      type: [
        { name: "Intern", value: "INTERN" },
        { name: "Extern", value: "EXTERN" },
      ],
      section: [
        { name: "HWS", value: "HWS" },
        { name: "SFV", value: "SFV" },
        { name: "ZDS", value: "ZDS" },
      ],
      status: [
        { name: "Entwurf", value: "DRAFT" },
        { name: "Veröffentlicht", value: "ONLINE" },
        { name: "Abgesagt", value: "CANCELLED" },
        { name: "Konflikt", value: "CONFLICT" },
        { name: "Abgeschlossen", value: "FINISHED" },
        { name: "Unabgeschlossen", value: "NO_FINISHED" },
      ],
    },
  },
  hints: {
    status: "",
    locked: "",
    section: "",
    label: "",
    description: "",
    type: "",
    startAt: "",
    endAt: "",
    finishedAt: "",
    tags: "",
    sfvTenantId: ""
  },
  options: {
    sortBy: [
      { name: "Sortierung: Geändert (absteigend)", value: "updatedAt_DESC" },
      { name: "Sortierung: Geändert (aufsteigend)", value: "updatedAt_ASC" },
      { name: "Sortierung: Angelegt (absteigend)", value: "createdAt_DESC" },
      { name: "Sortierung: Angelegt (aufsteigend)", value: "createdAt_ASC" },
      { name: "Sortierung: Beginn (absteigend)", value: "startAt_DESC" },
      { name: "Sortierung: Beginn (aufsteigend)", value: "startAt_ASC" },
    ],
    perPage: ["10", "20", "30", "40", "50", "100"],
  },
});

export default state;
