import { ifAuthenticated } from "@/router/auth";

const sfvAdministrationResources = [
  {
    path: "/sfv/administration/resources",
    name: "SFVAdministrationResources",
    component: () =>
      import("@/modules/sfv/administration/resources/views/Resources.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "sfv_administration_resources" },
  },
  {
    path: "/sfv/administration/resources/create",
    name: "SFVAdministrationResourcesCreate",
    component: () =>
      import("@/modules/sfv/administration/resources/views/ResourcesCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "sfv_administration_resources" },
  },
  {
    path: "/sfv/administration/resources/:id/edit",
    name: "SFVAdministrationResourcesEdit",
    component: () =>
      import("@/modules/sfv/administration/resources/views/ResourcesEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "sfv_administration_resources" },
  },
];

export default sfvAdministrationResources;
