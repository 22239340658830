import { ifAuthenticated } from "@/router/auth";

const hwsGenerallyTags = [
  {
    path: "/hws/generally/tags",
    name: "HWSGenerallyTags",
    component: () =>
      import("@/modules/hws/generally/tags/views/Tags.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "hws_generally_tags" },
  },
  {
    path: "/hws/generally/tags/create",
    name: "HWSGenerallyTagsCreate",
    component: () =>
      import("@/modules/hws/generally/tags/views/TagsCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "hws_generally_tags" },
  },
  {
    path: "/hws/generally/tags/:id/edit",
    name: "HWSGenerallyTagsEdit",
    component: () =>
      import("@/modules/hws/generally/tags/views/TagsEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "hws_generally_tags" },
  },
];

export default hwsGenerallyTags;
