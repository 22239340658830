const state = () => ({
  errors: [],
  loading: true,
  data: [],
  total: 0,
  search: "",
  section: "",
  tag: "",
  location: "",
  status: "",
  category: "",
  archived: "",
  sortBy: "updatedAt_DESC",
  page: 1,
  perPage: "10",
  inProcess: false,
  loadingTags: false,
  loadingLocations: false,
  create: {
    inProcess: false,
    errors: [],
    lists: {
      categories: [
        { name: "Raum", value: "ROOM" },
        { name: "Onlineraum", value: "ONLINEROOM" },
        { name: "Lernplattform", value: "LMS" },
        { name: "Fläche", value: "AREA" },
        { name: "Gerät", value: "DEVICE" },
        { name: "Wohnung", value: "APARTMENT" },
      ],
    },
  },
  edit: {
    loading: false,
    inProcess: false,
    errors: [],
  },
  filter: {
    loading: false,
    dynamic: {
      tag: [],
      location: [],
    },
    static: {
      category: [
        { name: "Raum", value: "ROOM" },
        { name: "Onlineraum", value: "ONLINEROOM" },
        { name: "Lernplattform", value: "LMS" },
        { name: "Fläche", value: "AREA" },
        { name: "Gerät", value: "DEVICE" },
        { name: "Wohnung", value: "APARTMENT" },
      ],
      status: [
        { name: "Aktiviert", value: "ENABLED" },
        { name: "Deaktiviert", value: "DISABLED" },
      ],
    },
  },
  hints: {
    status: "",
    locked: "",
    label: "",
    description: "",
    allLocationId: "",
    category: "",
    onlineRoomUrl: "",
    lmsUrl: "",
    tags: "",
  },
  options: {
    sortBy: [
      { name: "Sortierung: Geändert (absteigend)", value: "updatedAt_DESC" },
      { name: "Sortierung: Geändert (aufsteigend)", value: "updatedAt_ASC" },
      { name: "Sortierung: Angelegt (absteigend)", value: "createdAt_DESC" },
      { name: "Sortierung: Angelegt (aufsteigend)", value: "createdAt_ASC" },
    ],
    perPage: ["10", "20", "30", "40", "50", "100"],
  },
});

export default state;
