import Vue from "vue";
import Vuex from "vuex";

import router from "../router";

import auth from "./modules/auth/";
import profile from "./modules/profile/";
import administrationUsers from "./modules/administrationUsers/";
import zds from "./modules/zds/";
import sfv from "./modules/sfv/";
import hws from "./modules/hws/";
import _ from "lodash";
import api from "@/api";

Vue.use(Vuex);

const loadLoginData = () => {
  const loginDataJson = localStorage.getItem("loginData");
  if (loginDataJson) {
    const loginData = JSON.parse(loginDataJson);
    return loginData;
  } else {
    return null;
  }
};

export default new Vuex.Store({
  state: {
    version: "0.8.5",
    loginData: loadLoginData(),
    sessionError: false,
  },
  mutations: {
    setLoginData(state, loginData) {
      state.loginData = loginData;
    },
    setSessionError(state, value) {
      state.sessionError = value;
    },
  },
  actions: {
    gobalError({ commit, state }, err) {
      let errorMsg = "";

      if (err.response) {
        if (err.response.status == 401) {
          if (state.sessionError) {
            return;
          }

          commit("setSessionError", true);
          localStorage.removeItem("loginData");
          commit("setLoginData", null);

          router.push("/login");
          errorMsg = "Es ist ein Problem mit der Sitzung aufgetreten!";
        } else {
          errorMsg = "Es ist ein Fehler am Server aufgetreten!";
        }
      } else {
        errorMsg = "Es ist ein Fehler am Client aufgetreten!";
      }

      this._vm.$buefy.toast.open({
        message: errorMsg,
        type: "is-danger",
      });
    },

    newVersionCheck({ state }) {
      return new Promise((resolve, reject) => {
        api
          .post("/versioncheck", {
            version: state.version,
          })
          .then((response) => {
            if (response.data.success) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {
    navigationList: (state) => {
      if (_.isEmpty(state.loginData)) {
        return [];
      }

      return state.loginData.navigation;
    },
    loginFullName: (state) => {
      if (_.isEmpty(state.loginData)) {
        return "--";
      }

      return state.loginData.forename + " " + state.loginData.surname;
    },
    rolesList: (state) => {
      if (_.isEmpty(state.loginData)) {
        return [];
      }

      return state.loginData.roles;
    },
    allowModules: (state) => {
      if (_.isEmpty(state.loginData)) {
        return [];
      }

      return state.loginData.allowModules;
    },
    groupsLevel1: (state) => {
      if (_.isEmpty(state.loginData)) {
        return [];
      }

      return state.loginData.allowGroups.filter(
        (item) => item.type === "bundesverband"
      );
    },
    groupsLevel2: (state) => {
      if (_.isEmpty(state.loginData)) {
        return [];
      }

      return state.loginData.allowGroups.filter(
        (item) => item.type === "regionalverband"
      );
    },
    groupsLevel3: (state) => {
      if (_.isEmpty(state.loginData)) {
        return [];
      }

      return state.loginData.allowGroups.filter(
        (item) => item.type === "landesgruppe"
      );
    },
    groupsLevel4: (state) => {
      if (_.isEmpty(state.loginData)) {
        return [];
      }
      return state.loginData.allowGroups.filter(
        (item) => item.type === "bezirksgruppe"
      );
    },
    groupsLevel5: (state) => {
      if (_.isEmpty(state.loginData)) {
        return [];
      }
      return state.loginData.allowGroups.filter(
        (item) => item.type === "kreisgruppe"
      );
    },
    groupsAll: (state) => {
      if (_.isEmpty(state.loginData)) {
        return [];
      }
      return state.loginData.allowGroups;
    },
    isAuthenticated: (state) => !_.isEmpty(state.loginData),
    version: (state) => {
      return state.version;
    },
  },
  modules: {
    auth,
    profile,
    administrationUsers,
    zds,
    sfv,
    hws,
  },
});
