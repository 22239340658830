import { ifAuthenticated } from "@/router/auth";

const hwsGenerallyHolidays = [
  {
    path: "/hws/generally/holidays",
    name: "HWSGenerallyHolidays",
    component: () =>
      import("@/modules/hws/generally/holidays/views/Holidays.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "hws_generally_holidays" },
  },
  {
    path: "/hws/generally/holidays/create",
    name: "HWSGenerallyHolidaysCreate",
    component: () =>
      import("@/modules/hws/generally/holidays/views/HolidaysCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "hws_generally_holidays" },
  },
  {
    path: "/hws/generally/holidays/:id/edit",
    name: "HWSGenerallyHolidaysEdit",
    component: () =>
      import("@/modules/hws/generally/holidays/views/HolidaysEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "hws_generally_holidays" },
  },
];

export default hwsGenerallyHolidays;
