const state = () => ({
  errors: [],
  inProcess: false,
  hints: {
    startAt: "",
    endAt: "",
    category: "",
    allLocationId: "",
    resource: "",
    tag: "",
  },
  lists: {
    categories: [
      { id: "ROOM", name: "Raum" },
      { id: "ONLINEROOM", name: "Onlineraum" },
      { id: "LMS", name: "Lernplattform" },
      { id: "AREA", name: "Fläche" },
      { id: "DEVICE", name: "Gerät" },
      { id: "APARTMENT", name: "Wohnung" },
    ],
  },
});

export default state;
